<template>
  <page-layout ref="layout">
    <template #breadcrumbs="{ }">
      <b-breadcrumb-item :text="`All-State - ${$store.state.settings.app.current.title}`" />
      <b-breadcrumb-item text="Selections" :to="{ name: 'all-state-selections' }" />
      <b-breadcrumb-item v-if="table.filter.instrument" active>{{ instruments.items.find(instrument => instrument.id === table.filter.instrument).name }}</b-breadcrumb-item>
    </template>

    <template #actions="{ state }">
      <can v-if="isEnabled && canEdit" do="update" on="all-state-selection">
        <b-button :disabled="!save.changes.length" variant="link" size="sm" @click="saveChanges(state)">
          <font-awesome-icon icon="fa-solid fa-cloud-arrow-up" class="mr-50"/> Update
        </b-button>
      </can>
    </template>

    <template #dropdown-options>
      <b-dropdown-item @click="ui.layout.showLeftColumn = !ui.layout.showLeftColumn">
        <feather-icon :icon="ui.layout.showLeftColumn ? 'Minimize2Icon' : 'Maximize2Icon'"/>
        <span class="align-middle ml-50">{{ ui.layout.showLeftColumn ? 'Hide' : 'Show' }} Sidebar</span>
      </b-dropdown-item>
      <b-dropdown-divider/>
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>

    </template>

    <template #loading="{ state }">
      <overlay-loading :items="[
          { state: applications.loading, desc: 'Loading Applications', loaded: applications.loaded},
          { state: state.loading, desc: 'Rendering Template'},
          ]">
      </overlay-loading>
    </template>

    <template #content="{ state }">
      <template v-if="state.error || !isEnabled">
        <b-alert v-if="!isEnabled" show variant="danger" class="font-small-3">
          <b-icon-exclamation-circle class="mr-50"/> {{ setting.message.enabled ? setting.message.text : 'Selections are currently disabled.' }}
        </b-alert>
				<b-alert v-if="ui.error.show" show variant="danger" class="font-small-3">
          <b-icon-exclamation-circle class="mr-50"/> {{ ui.error.message }}
				</b-alert>
      </template>
      <template v-else>
				<b-row>
					<b-col v-if="ui.layout.showLeftColumn" class="col-12 col-xxxl-3 d-print-none">
						<template>
							<!-- Filters -->
							<b-card-actions title="Filters" action-collapse class="d-print-none">
                <b-form-group label="Instrument" label-for="filter-instrument">
                  <template #label>
                    Instrument (<span class="text-danger">required</span>)
                  </template>
                  <v-select v-model="table.filter.instrument"
                            input-id="filter-instrument"
                            label="name"
                            :reduce="option => option.id"
                            :options="instruments.items"
                            :loading="instruments.loading"
                            :disabled="instruments.loading || updatableValues.length > 0"
                            class="w-100 d-print-none"
                            @input="listApplications(null, [])">
                    <template #option="{ id, name }">
                      <div v-if="id === 'null'" class="text-danger">{{ name }}</div>
                      <div v-else>{{ name }}</div>
                    </template>
<!--                    <template #selected-option="{ name }">
                      <span>{{ name.first }} {{ name.last }}</span>
                    </template>-->
                  </v-select>
                  <b-alert :show="updatableValues.length > 0" variant="danger" class="mt-50 font-small-3">Instrument can not be changed while there are pending changes.</b-alert>
                </b-form-group>
								<b-form-group label="Group" label-for="filter-group">
									<v-select v-model="table.filter.group"
                            input-id="filter-group"
														label="name"
														:reduce="option => option.value"
														:options="[{ name: 'Group 1', value: '1' }, { name: 'Group 2', value: '2' }, { name: 'Group 3', value: '3' }]"
														class="w-100 d-print-none"/>
								</b-form-group>
								<b-form-group label="Ensemble" label-for="filter-ensemble">
									<v-select v-model="table.filter.ensemble"
                            input-id="filter-ensemble"
														label="name"
														:reduce="option => option.id"
														:options="ensembles.items"
                            :loading="ensembles.loading"
                            :disabled="ensembles.loading"
														class="w-100 d-print-none"/>
								</b-form-group>
							</b-card-actions>
              <template v-if="isEnabled && canEdit && $can('update', 'all-state-selection')">
                <!-- Pending Changes -->
                <b-alert show variant="primary" class="d-print-none">
                  <b-list-group>
                    <!-- Cognito User -->
                    <b-list-group-item>
                      <div class="d-flex justify-content-between align-items-center">
                        <div class="font-small-3">Pending Changes ({{ updatableValues.length }})</div>
                        <div class="d-flex align-items-center">
                          <b-button size="sm" variant="link"
                                    :aria-expanded="ui.changes.expanded ? 'true' : 'false'"
                                    aria-controls="ui.changes.expanded"
                                    class="px-0"
                                    @click="ui.changes.expanded = !ui.changes.expanded">
                            {{ ui.changes.expanded ? 'Hide' : 'Show' }} Details
                          </b-button>
                        </div>
                      </div>
                      <b-overlay :show="save.processing" variant="white" :opacity="1">
                        <vue-perfect-scrollbar :settings="{ maxScrollbarLength: 150,wheelPropagation: false }" class="ps-changes">
                          <b-collapse id="ui.changes.expanded" v-model="ui.changes.expanded">
                            <b-list-group v-if="updatableValues.length && !save.processing" flush class="pt-50">
                              <b-list-group-item v-for="(item, index) in updatableValues" :key="index" class="px-0 py-25">
                                <div class="d-flex justify-content-between align-items-start">
                                  <div>
                                    <small class="text-capitalize font-weight-bold">{{ item.type }} Selection</small>
                                    <small class="d-block">Application: {{ item.id }}</small>
                                    <small v-for="(change, i2) in item.changes" :key="index + '-' + i2" class="d-block">
                                      Change <span class="text-capitalize">{{ change.type }}</span> {{ fromToText(change.from, change.to) }}
                                    </small>
                                  </div>
                                  <b-button variant="link" size="sm" class="btn-icon" @click="searchAndExpandItem(item.id)">
                                    <b-icon-search />
                                  </b-button>
                                </div>
                              </b-list-group-item>
                            </b-list-group>
                            <small v-else class="pt-50">Nothings been updated yet.</small>
                          </b-collapse>
                        </vue-perfect-scrollbar>
                      </b-overlay>

                    </b-list-group-item>
                  </b-list-group>
                </b-alert>
                <!-- Potential Issues -->
                <b-alert :show="potentialIssues.length > 0" variant="secondary" class="d-print-none">
                  <b-list-group>
                    <b-list-group-item>
                      <div class="d-flex justify-content-between align-items-center">
                        Potential Issues ({{ potentialIssues.length }})
                        <div class="d-flex align-items-center">
                          <b-button size="sm" variant="link"
                                    :aria-expanded="ui.potentialIssues.expanded ? 'true' : 'false'"
                                    aria-controls="ui.potentialIssues.expanded"
                                    class="px-0"
                                    @click="ui.potentialIssues.expanded = !ui.potentialIssues.expanded">
                            {{ ui.potentialIssues.expanded ? 'Hide' : 'Show' }} Details
                          </b-button>
                          <!--                      <b-icon icon="circle" variant="primary"/>-->
                        </div>
                      </div>
                      <!-- Element to collapse -->
                      <b-overlay :show="save.processing" variant="white" :opacity="1">
                        <vue-perfect-scrollbar :settings="{ maxScrollbarLength: 150,wheelPropagation: false }" class="ps-changes">
                          <b-collapse id="ui.potentialIssues.expanded" v-model="ui.potentialIssues.expanded">
                            <b-list-group flush class="pt-50">
                              <b-list-group-item v-for="(item, index) in potentialIssues" :key="index" class="px-0 py-25">
                                <div class="d-flex justify-content-between align-items-start">
                                  <div>
                                    <small class="text-capitalize font-weight-bold">Duplicate Student</small>
                                    <small class="d-block">{{ item.student.name.first }} {{ item.student.name.last }} appears {{ item.count }} times.</small>
                                  </div>
                                  <b-button variant="link" size="sm" class="btn-icon" @click="searchAndExpandItems(item.student.id)">
                                    <b-icon-search />
                                  </b-button>
                                </div>
                              </b-list-group-item>
                            </b-list-group>
                          </b-collapse>
                        </vue-perfect-scrollbar>
                      </b-overlay>

                    </b-list-group-item>
                  </b-list-group>
                </b-alert>
              </template>
						</template>
						<template v-if="state.debug">
							<debug collapsed>
								{{ save.changes }}
							</debug>
							<debug collapsed>
								{{ updatableValues }}
							</debug>
						</template>
					</b-col>
					<b-col>
						<b-overlay :show="save.processing || save.complete" no-center fixed>
							<template #overlay>
								<b-alert show variant="primary" class="d-print-none">
									<b-list-group>
										<b-list-group-item>
											<div class="d-flex justify-content-between align-items-center">
												Processed Changes ({{ save.computedChanges.length }})
												<div class="d-flex align-items-center">
													<b-button size="sm" variant="link"
																		:aria-expanded="ui.processed.expanded ? 'true' : 'false'"
																		aria-controls="ui.processed.expanded"
																		class="mr-1"
																		@click="ui.processed.expanded = !ui.processed.expanded">
														{{ ui.processed.expanded ? 'Hide' : 'Show' }} Details
													</b-button>
													<b-spinner v-if="save.processing" small :class="getSaveClass()"></b-spinner>
													<b-icon v-else :icon="getSaveIcon(save)" :variant="getSaveVariant(save)" :class="getSaveClass()"/>
												</div>
											</div>
											<div v-if="save.processing" class="my-1">
												<small class="mb-25">{{ save.status }}</small>
												<b-progress>
													<b-progress-bar :value="save.progress" :max="save.computedChanges.length"></b-progress-bar>
												</b-progress>
											</div>

											<!-- Element to collapse -->
											<vue-perfect-scrollbar ref="processed-scrollbar" :settings="{ maxScrollbarLength: 150,wheelPropagation: false }" class="ps-processed">
												<b-collapse id="ui.processed.expanded" v-model="ui.processed.expanded">
													<b-list-group v-if="save.computedChanges.length" flush class="pt-50">
														<b-list-group-item v-for="(item, index) in save.computedChanges" :key="index" class="px-0 py-25">
															<div class="d-flex justify-content-between align-items-start">
																<div>
																	<small class="text-capitalize font-weight-bold">{{ item.type }} Selection</small>
																	<small class="d-block">Application: {{ item.id }}</small>
																	<small v-for="(change, i2) in item.changes" :key="index + '-' + i2" class="d-block">
																		<span class="text-capitalize">{{ item.type }} {{ change.type }}</span> {{ fromToText(change.from, change.to) }}
																	</small>
																	<small class="d-block">
																		<span class="text-capitalize">{{ item.type }}d {{ item.updatedAt | date }}</span>
																	</small>
																	<small v-if="item.error" class="d-block text-danger">Error: {{ item.error }}</small>
																</div>
																<b-spinner v-if="item.saving" small></b-spinner>
																<b-icon v-else :icon="item.icon" :variant="item.variant" class="mt-25"/>
															</div>
														</b-list-group-item>
													</b-list-group>
													<small v-else class="pt-50">Nothings been updated yet.</small>
												</b-collapse>
											</vue-perfect-scrollbar>
										</b-list-group-item>
									</b-list-group>
									<div class="d-flex justify-content-end">
										<b-button v-if="save.complete" block class="mt-50 shadow-none" variant="outline-white" @click="save.complete = false">Back to Table</b-button>
									</div>
								</b-alert>
							</template>
							<template #default>
								<b-card v-if="!save.processing && !save.complete" no-body class="table-card">
									<template #header>
										<div class="d-flex flex-grow-1 d-print-none">
											<b-input-group class="input-group-merge">
												<b-form-input
														id="search-apps"
														v-model="table.search"
														:debounce="250"
														autocomplete="off"
														size="md"
														placeholder="Search Applications"
												/>
												<b-input-group-append v-if="table.search" is-text>
													<feather-icon icon="XIcon" class="text-muted" @click="table.search = ''" />
												</b-input-group-append>
												<b-input-group-append is-text>
													<feather-icon icon="SearchIcon" class="text-muted" />
												</b-input-group-append>
											</b-input-group>
										</div>
									</template>
									<template #default>
										<validation-observer ref="table-observer">
											<div id="table-container">
												<b-table id="table" ref="table"
																 class="position-relative"
																 table-class="table-flush page-break"
																 thead-class="thead-light"
																 tbody-class="border-0"
																 :tbody-tr-class="getGroupClass"
																 details-td-class="td-details-row"
																 primary-key="id"
																 responsive
																 :busy="table.busy"
																 :items="sortedItems"
																 :fields="table.fields"
                                 show-empty
																	@context-changed="getApplicationDetails">

													<template #table-busy>
														<slot name="overlay">
															<overlay-loading :items="[
                              { state: applications.loading, desc: 'Loading Applications', loaded: applications.loaded},
                            ]" />
														</slot>
													</template>

													<template #row-details="row">
														<b-overlay :show="!row.item.hasDetails" opacity="1" @shown="getApplicationDetails(row.item)">
															<b-card no-body header-class="pb-0" class="mb-0 d-print-none">
																<template v-if="row.item.hasDetails">
																	<b-tabs card nav-wrapper-class="pt-1 pb-0">
																		<b-tab lazy title="Details">
																			<application-details :current-user="user"
																													 :festival.sync="row.item.festival"
																													 :form.sync="row.item.form"
																													 :instrument.sync="row.item.instrument"
																													 :editable="false" :show-heading="false"/>
																		</b-tab>
																		<b-tab lazy title="Student">
																			<application-student :current-user="user"
																													 :student.sync="row.item.student"
																													 :create-student="false"
																													 :existing-students-options="{ show: false, clearable: false }"
																													 :editable="false" :show-heading="false"/>
																		</b-tab>
																		<b-tab lazy title="Questions">
																			<application-questions :form="row.item.form"
																														 :questions.sync="row.item.questions"
																														 :editable="false" :show-heading="false"/>
																		</b-tab>
																		<b-tab lazy title="Grading">
																			<application-grading :form="row.item.form"
																													 :student="row.item.student"
																													 :grading.sync="row.item.grading"
																													 :recommendation.sync="row.item.recommendation"
																													 :comments.sync="row.item.comments"
																													 :editable="false" :show-heading="false"/>
																		</b-tab>
																	</b-tabs>
																</template>
																<template v-else>
																	<b-alert show variant="light">Loading</b-alert>
																</template>
															</b-card>
														</b-overlay>
													</template>

													<template #cell(student)="row">
														<b-media vertical-align="center" no-body class="d-print-none">
															<b-media-aside class="my-auto">
																<b-avatar button
																					variant="primary" size="2.5em"
																					badge-variant="primary"
																					@click="row.toggleDetails">
																	<font-awesome-icon icon="fas fa-graduation-cap"></font-awesome-icon>
																	<template #badge>
																		<b-icon :icon="row.item._showDetails === true ? 'chevron-up' : 'chevron-down'" />
																	</template>
																</b-avatar>
															</b-media-aside>
															<b-media-body class="align-self-center" @click="row.toggleDetails">
																<div>{{ row.item.student.name.first }} {{ row.item.student.name.last }}</div>
																<small v-if="row.item.student.school && row.item.student.school.name" class="student-school d-print-none">{{ row.item.student.school.name.legal }}</small>
                                <small v-else class="text-danger student-school d-print-none">No School</small>
															</b-media-body>
														</b-media>
														<span class="d-none d-print-block">{{ row.item.student.name.first }} {{ row.item.student.name.last }}</span>
													</template>

													<template #cell(festival.name)="data">
                            <template v-if="data.item.festival">
                              {{ data.item.festival.name }}
                            </template>
                            <template v-else>
                              <span class="text-danger">No Festival</span>
                            </template>
													</template>

                          <template #cell(festival.zone.name)="data">
                            <template v-if="data.item.festival && data.item.festival.zone">
                              {{ getZoneName(data.item.festival) }}
                            </template>
                            <template v-else>
                              <span class="text-danger">No Zone</span>
                            </template>
                          </template>

													<template #cell(selection.ranking.state)="data">
                            <b-input-group>
                              <b-input v-model="data.item.selection.state" number type="number"
                                       :tabindex="data.item.tabIndex.state"
                                       :debounce="250"
                                       class="d-print-none"
                                       :disabled="!$can('update', 'all-state-selection', 'state') || !canEdit"
                                       @update="onChange('state', $event, rules.state, data.item)"
                                       />
                            </b-input-group>
														<span class="d-none d-print-block">{{ data.item.selection.state }}</span>
													</template>

													<template #cell(selection.ensemble)="data">
                            <b-input-group>
                              <v-select v-model="data.item.selection.ensemble"
                                        :disabled="!$can('update', 'all-state-selection', 'ensemble') || !canEdit"
                                        :options="ensembles.items" label="name"
                                        :reduce="option => option.id"
                                        :filter="filterEnsembles"
                                        :tabindex="data.item.tabIndex.ensemble"
                                        :select-on-tab="true"
                                        append-to-body :calculate-position="withPopper"
                                        class="font-small-3 d-print-none table-select"
                                        @input="onChange('ensemble', $event, rules.score, data.item)">
                                <!--@search:blur="onBlur('ensemble', data.item)"-->
                                <template #option="{ name, code, state}">
                                  <div>
                                    {{ name }} <small>({{ code }})</small>
                                    <feather-icon v-if="state && !state.enabled" icon="LockIcon" class="text-danger float-right" />
                                  </div>
                                </template>
                                <template #selected-option="{ name }">
                                  <span>{{ name }}</span>
                                </template>
                              </v-select>
                            </b-input-group>
														<span class="d-none d-print-block">{{ getEnsembleCode(data.item.selection.ensemble) }}</span>
													</template>

													<template #cell(selection.part)="data">
                            <b-input-group >
                              <v-select v-model="data.item.selection.part"
                                        :disabled="!$can('update', 'all-state-selection', 'part') || !canEdit"
                                        :options="getParts(data.item.selection.ensemble)"
                                        :tabindex="data.item.tabIndex.part"
                                        append-to-body :calculate-position="withPopper"
                                        :select-on-tab="true" class="d-print-none table-select"
                                        @input="onChange('part', $event, rules.score, data.item)">
                                <template #selected-option="option">
                                  <span>{{ option.label }}</span>
                                </template>
                              </v-select>
                            </b-input-group>
														<span class="d-none d-print-block">{{ data.item.selection.part }}</span>
													</template>

                          <template #cell(row-options)="data">
                            <table-row-options :index="data.index" toggle-class="px-50">
                              <b-dropdown-item :to="{ name: 'all-state-application', params: { id: data.item.id } }"
                                               class="table-row-option-view">
                                <feather-icon icon="FileTextIcon" />
                                <span class="align-middle ml-50">View Application</span>
                              </b-dropdown-item>
                              <b-dropdown-item :to="{ name: 'all-state-student', params: { id: data.item.student.id } }"
                                               class="table-row-option-view">
                                <feather-icon icon="FileTextIcon" />
                                <span class="align-middle ml-50">View Student</span>
                              </b-dropdown-item>
                              <b-dropdown-divider/>
                              <b-dropdown-item-button class="table-row-option-details" @click="data.toggleDetails">
                                <feather-icon :icon="`Toggle${data.item._showDetails ? 'Left': 'Right'}Icon`"/>
                                <span class="align-middle ml-50">Toggle Row Details</span>
                              </b-dropdown-item-button>
                            </table-row-options>
                          </template>
												</b-table>
											</div>
										</validation-observer>
									</template>
								</b-card>
							</template>
						</b-overlay>
					</b-col>
				</b-row>
      </template>
    </template>

    <template #debug>
      <b-row>
        <b-col cols="4">
          <debug title="Table Items">{{ table.items.slice(0, 10) }}</debug>
        </b-col>
        <b-col cols="4">
          <debug title="Table Initial Items">{{ table.initialItems.slice(0, 10) }}</debug>
        </b-col>
        <b-col cols="4">
          <debug title="Table Initial Items">{{ table.filter }}</debug>
        </b-col>
      </b-row>
    </template>
  </page-layout>
</template>

<script>
import vSelect from 'vue-select'
import {uuid} from 'vue-uuid';
import PageLayout from '@/components/PageLayout.vue';
import {API, Auth, graphqlOperation} from 'aws-amplify';

import {
  createSelection,
  deleteSelection,
  getApplication,
  getApplicationDetails,
  getSetting,
  getUser,
  listApplications, listApplicationsWithNullInstrument, listEnsembles, listInstruments,
  onUpdateApplication,
  updateApplication,
  updateSelection
} from './selection-all';

import notify from '@/mixins/notify.mixin';
import OverlayLoading from '@/components/OverlayLoading.vue';
import Fuse from 'fuse.js';
import {validate} from 'vee-validate';
import Ripple from 'vue-ripple-directive';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import ApplicationDetails from '@/views/all-state/application/ApplicationDetails.vue';
import ApplicationStudent from '@/views/all-state/application/ApplicationStudent.vue';
import ApplicationQuestions from '@/views/all-state/application/ApplicationQuestions.vue';
import ApplicationGrading from '@/views/all-state/application/ApplicationGrading.vue';
import popper from '@/mixins/popper.mixin';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import TableRowOptions from '@/components/TableRowOptions.vue';


export default {
  directives: {
    Ripple,
  },
  filters: {
    date(value) {
      return value ? new Intl.DateTimeFormat('en', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }).format(new Date(value)) : null
    }
  },
  components: {
    TableRowOptions,
    BCardActions,
		ApplicationGrading,
		ApplicationDetails,
		ApplicationStudent,
		ApplicationQuestions,
    OverlayLoading,
    PageLayout,
    vSelect,
    VuePerfectScrollbar
  },
  mixins: [notify, popper],
  data() {
    return {
      user: null,
			setting: null,
      instruments: {
        loading: true,
        items: []
      },
      ensembles: {
        loading: true,
        items: []
      },
      applications: {
        loading: false,
        loaded: 0
      },
      table: {
        busy: false,
        fields: [
					/*{ key: 'group', label: 'Group', class: 'page-break' },*/
          { key: 'student', label: 'Student', class: 'col-student page-break' },
          { key: 'festival.name', label: 'Festival', class: 'col-festival page-break' },
          { key: 'festival.zone.name', label: 'Zone', class: 'col-zone page-break' },
          { key: 'ranking.score', label: 'Score', class: 'col-score page-break' },
          { key: 'ranking.local', label: 'Local Rank', class: 'col-local page-break' },
          { key: 'selection.ranking.state', label: 'State Rank', class: 'col-state page-break' },
          { key: 'selection.ensemble', label: 'Ensemble', class: 'col-ensemble page-break' },
          { key: 'selection.part', label: 'Part', class: 'col-part page-break' },
          /*{ key: 'row-options', label: '', tdClass: 'align-middle', thClass: 'border-0', class: 'table-row-options' },*/
        ],
        filter: {
          instrument: null,
          group: null,
          ensemble: null,
          part: null
        },
        search: null,
        items: [],
        initialItems: [],
        subscription: {
          onUpdate: null
        },
      },
      save: {
        complete: false,
        processing: false,
        status: '',
        changes: [],
        computedChanges: [],
        errors: [],
        progress: 0,
        processed: []
      },
      ui: {
        layout: {
          showLeftColumn: true,
        },
        changes: {
          expanded: false
        },
        processed: {
          expanded: true
        },
        potentialIssues: {
          expanded: false
        },
        error: {
            show: false,
            message: ''
        }
      },
      rules: {
        state: { required: false, numeric: true, min_value: 0, max_value: 999 },
        ensemble: { required: false },
        part: { required: false },
      },
      icon: 'fas fa-check',
    }
  },
  computed: {
		isEnabled() {
			const { enabled, override, restrictions } = this.setting;
			const { user } = this;

			if (this.$can('manage')) {
				return true;
			}

			// If the current user is in an override group or is an override user then return true
			const canBypassDisabledState = override.groups.some(group => user.groups.includes(group)) || override.users.some(u => user.id === u.id);

			// If the setting is enabled or the user can bypass the disabled state then return true
			if (enabled || canBypassDisabledState) {
				return true; // If enabled, or if the user can bypass the disabled state and is not in a restricted group
			}
			return false; // If disabled and the user cannot bypass the disabled state
		},
    canEdit() {
      const { enabled, override, restrictions } = this.setting;
      const { user } = this;

      if (this.$can('manage')) { return true; }

      // If the current user is in an override group or is an override user then return true
      const canBypassDisabledState = override.groups.some(group => user.groups.includes(group)) || override.users.some(u => user.id === u.id);

      // If the setting is enabled or the user can bypass the disabled state then return true
      if (enabled || canBypassDisabledState) {
        // If restrictions are enabled then check if the user is in a restricted group
        if(restrictions.enabled) {
          const isInRestrictedGroup = restrictions.groups.some(group => user.groups.includes(group.name));

          if (isInRestrictedGroup) {
            const currentDate = new Date();
            const hasValidDateRange = restrictions.groups.filter(group => group.enabled && user.groups.includes(group.name)).some(group => {
              try {
                const startDateParts = group.start.split('-').map(part => parseInt(part, 10));
                const startDate = new Date(startDateParts[0], startDateParts[1] - 1, startDateParts[2]);

                const endDateParts = group.end.split('-').map(part => parseInt(part, 10));
                const endDate = new Date(endDateParts[0], endDateParts[1] - 1, endDateParts[2], 23, 59, 59, 999)

                return currentDate >= startDate && currentDate <= endDate;
              }
              catch (e) {
                console.error('Error parsing date range', e);
                return false;
              }
            })
            return hasValidDateRange;
          }
        }
        return true; // If enabled, or if the user can bypass the disabled state and is not in a restricted group
      }
      return false; // If disabled and the user cannot bypass the disabled state
    },

    sortedItems() {
      if(this.table.busy || this.table.items.length === 0) {
        return []
      }

      const fuse = new Fuse(this.table.items, {
        useExtendedSearch: true,
        threshold: 0.2,
        keys: [
          'id',
          'group',
          'selection.ensemble',
          'selection.part',
          'student.id',
          'student.name.full',
        ]
      })

      const query = { $and: [ ] }
      if(this.table.filter.group) { query.$and.push({group: `'${this.table.filter.group}` }) }
      if(this.table.filter.ensemble) { query.$and.push({'selection.ensemble': `'${this.table.filter.ensemble}` }) }
      if(this.table.filter.part) { query.$and.push({'selection.part': `'${this.table.filter.part}` }) }

      if(this.table.search) {
        query.$and.push({
          $or: [
            { id: `'${this.table.search}` },
            { 'student.id': `'${this.table.search}` },
            { 'student.name.full': `'${this.table.search}`}
          ]
        })
      }
      let items;
      if(query.$and.length) {
        items = fuse.search(query).map(({ item }) => item).sort(this.compareItems())
      }
      else {
        items = this.table.items.slice().sort(this.compareItems())
      }

      return items.map((item, index) => ({...item,
        tabIndex: {
          state: (index + 1000),
            ensemble: (index + 1000) + this.table.items.length,
            part: (index + 1000) + (this.table.items.length * 2)
        }
      }))
    },
    updatableValues() {
      const values = []
      this.table.initialItems.forEach(item => {
        const changes = this.save.changes.filter(change => change.id === item.id)
        if(changes.length) {
          const input = {
            id: item.id,
            selection: { id: item?.selection?.id, state: null, ensemble: null, part: null, invoice: item?.selection?.invoice },
            type: null,
            changes: [],
          }

          const stateChange = this.save.changes.find(change => change.id === item.id && change.key === 'state')
          if(stateChange) {
            input.selection.state = stateChange.value
            input.changes.push({ type: 'state rank', from: item.selection.state, to: stateChange.value})
          }
          else { input.selection.state = item.selection.state }

          const ensembleChange = this.save.changes.find(change => change.id === item.id && change.key === 'ensemble')
          if(ensembleChange) {
            input.selection.ensemble = ensembleChange.value
            input.changes.push({ type: 'ensemble', from: this.getEnsembleName(item.selection.ensemble), to: this.getEnsembleName(ensembleChange.value)})
          }
          else { input.selection.ensemble = item.selection.ensemble }

          const partChange = this.save.changes.find(change => change.id === item.id && change.key === 'part')
          if(partChange) {
            input.selection.part = partChange.value
            input.changes.push({ type: 'part', from: item.selection.part, to: partChange.value})
          }
          else { input.selection.part = item.selection.part }

          /** Determine Date **/
          input.date = new Date(Math.max(...changes.map(change => new Date(change.date)))).toISOString();

          /** Determine Type **/
          if(!input.selection?.id) { input.type = 'create' }
          else if(input?.selection?.id && input.selection.state === null && input.selection.ensemble === null && input.selection.part === null) { input.type = 'delete' }
          else { input.type = 'update' }

          values.push(input)
        }
      })
      return values.sort((a, b) => b.date.localeCompare(a.date))
    },
    potentialIssues() {
      const counter = { }
      this.table.items.forEach(obj => {
        const key = obj.student.id
        counter[key] = (counter[key] || 0) + 1
      })

      const output = []
      Object.entries(counter).forEach(entry => {
        const [key, value] = entry;
        if(value > 1 ) {
          output.push({ student: this.table.items.find(item => item.student.id === key).student, count: value})
        }
      });
      return output
    },
  },
	watch: {
		currentUser(value) {
			this.user = value
		}
	},
  async mounted() {
		await this.getCurrentUser()
		await this.getSetting()
    await this.listInstruments()
    await this.listEnsembles()

    /*if(this.isEnabled && !this.$refs.layout.state.error) {
        await this.listApplications()
    }*/

    await this.$nextTick(() => {
      setTimeout( () => { this.$refs.layout.state.loading = false }, 500);
    })

    if(this.$route?.query) {
      if(this.$route.query?.application) {
        this.searchAndExpandItem(this.$route.query.application)
      }
      else if(this.$route.query?.student) {
        this.searchAndExpandItems(this.$route.query.student)
      }
    }
  },
  beforeDestroy() {
    if(this.table?.subscriptions?.onUpdate) {
      this.table.subscriptions.onUpdate.unsubscribe()
    }
  },
  methods: {
    async refresh() {
      await this.getCurrentUser()
      await this.getSetting()
      await this.listInstruments()
      await this.listEnsembles()

     if(this.isEnabled && !this.$refs.layout.state.error && this.table.filter.instrument) {
        await this.listApplications()
      }
     else {
        this.table.items = []
      }
    },
		async getSetting() {
			const response = await API.graphql(graphqlOperation(getSetting, { key: 'selection' }));
			const setting = response.data.getSetting
			if(setting) {
				this.setting = JSON.parse(setting.value)
			}
		},
    async getCurrentUser() {
      /** Get Current User from Store **/
      const cognitoUser = await Auth.currentAuthenticatedUser()

      /** Get User from AppSync **/
      const response = await API.graphql(graphqlOperation(getUser, { id: cognitoUser.attributes['custom:user_id'] }));
      this.user = response.data.getUser
      this.user.groups = cognitoUser.signInUserSession.accessToken.payload['cognito:groups']
    },
    async listInstruments(nextToken, pagedItems) {
      this.instruments.loading = true
      const items = pagedItems || []

      const response = await API.graphql(graphqlOperation(listInstruments, { limit: 500, nextToken } ));
      items.push(...response.data.listInstruments.items)

      this.instruments.loaded = items.length
      if(response.data.listInstruments.nextToken) {
        await this.listInstruments(response.data.listApplications.nextToken, items)
      }
      else {
        this.instruments.items = items.sort((a, b) => a.name.localeCompare(b.name))
        this.instruments.items.push({ id: 'null', name: 'No Instrument' })
        this.instruments.loading = false
      }
    },
    async listEnsembles(nextToken, pagedItems) {
      this.ensembles.loading = true
      const items = pagedItems || []

      const response = await API.graphql(graphqlOperation(listEnsembles, { limit: 500, nextToken } ));
      items.push(...response.data.listEnsembles.items)

      this.ensembles.loaded = items.length
      if(response.data.listEnsembles.nextToken) {
        await this.listEnsembles(response.data.listEnsembles.nextToken, items)
      }
      else {
        this.ensembles.items = items.sort((a, b) => a.name.localeCompare(b.name))
        this.ensembles.loading = false
      }
    },
    async listApplications(nextToken, pagedApplications) {
      this.table.busy = true
      this.applications.loading = true
      const applications = pagedApplications || []
      const input = { instrumentId: this.table.filter.instrument, limit: 500, nextToken: nextToken }

      const response = await API.graphql(graphqlOperation( input.instrumentId === 'null' ? listApplicationsWithNullInstrument : listApplications, input));
      applications.push(...response.data.listApplications.items)

      this.applications.loaded = applications.length
      if(response.data.listApplications.nextToken) {
        await this.listApplications(response.data.listApplications.nextToken, applications)
      }
      else {
        this.applications.loading = false
        this.table.items = this.mapApplications(applications.filter(app => app.ranking.score > 0 && app.ranking.local > 0))
        this.table.initialItems = JSON.parse(JSON.stringify(this.table.items.map(item => ({ id: item.id, selection: item.selection }) ))) //json parse/stringify created a deep copy
        this.table.busy = false
      }
    },
		async getApplicationDetails(item) {
			if(item.hasDetails === false) {
				const response = await API.graphql(graphqlOperation(getApplicationDetails, { id: item.id } ));
				const application = response.data.getApplication
				if(application) {
					item.student.dob = application.student.dob
					item.student.grade = application.student.grade
					item.student.address = application.student.address
					item.student.email = application.student.email
					item.form = application.form
					item.questions = application.questions
					item.grading = application.grading
					item.recommendation = application.recommendation
					item.comments = application.comments
				}
				item.hasDetails = true
			}
		},
    async onUpdateApplication() {
      this.table.subscription.onUpdate = API.graphql(graphqlOperation(onUpdateApplication)).subscribe(async (sourceData) => {
        const updatedApp = sourceData.value.data.onUpdateApplication
        if(updatedApp) {
          const response = await API.graphql(graphqlOperation(getApplication, { id: updatedApp.id }));
          const application = this.mapApplication(response.data.getApplication)

          if(application) {
            const item = this.table.items.find(i => i.id === application.id)
            if(item) {
              item.ranking.local = application.ranking.local
              item.ranking.score = application.ranking.score
            }
            else {
              this.table.items.push(application)
            }

            this.syncNotification()
          }
        }
      });
    },

    mapApplications(items) {
      const sortedItems = items.sort((a, b) =>
          b.ranking.score - a.ranking.score
          || a.ranking.local - b.ranking.local
          || a.student.name.last.localeCompare(b.student.name.last)
          || a.student.name.first.localeCompare(b.student.name.first))

      return sortedItems.map((item) => this.mapApplication(item));
    },
    mapApplication(item) {
      if(!item) return null
      return {
        id: item.id,
        group: this.getGroup(item.ranking),
        student: {
          id: item.student?.id,
          name: {
            first: item.student?.name?.first,
            last: item.student?.name?.last,
            full: `${item.student?.name?.first} ${item.student?.name?.last}`
          },
          school: item.student.school
        },
				form: item?.form,
        instrument: item?.instrument,
        festival: item?.festival,
				questions: item?.questions,
				grading: item?.grading,
        ranking: item?.ranking,
        selection: {
          id: item?.selection?.id || null,
          part: item?.selection?.part || null,
          state: item?.selection?.ranking?.state || null,
          ensemble: item?.selection?.ensemble?.id || null,
          accepted: item?.selection?.accepted || null,
          invoice: item?.selection?.selectionInvoiceId || null
        },
        otherApplications: item.student.applications.items.filter(otherApp => otherApp.id !== item.id && otherApp.selection).map(otherApp => ({
          id: otherApp.id,
          instrument: otherApp.instrument?.name,
          festival: otherApp.festival?.name,
          ranking: otherApp.ranking,
          selection: {
            id: otherApp?.selection?.id,
            part: otherApp?.selection?.part,
            state: otherApp?.selection?.ranking?.state,
            ensemble: otherApp?.selection?.ensemble?.name,
            accepted: otherApp?.selection?.accepted,
            invoice: otherApp?.selection?.selectionInvoiceId
          }
        })),
				hasDetails: false, //used to show/hide and load additional application details
      }
    },
    compareItems() {
      return (a, b) =>
          b.ranking.score - a.ranking.score
          || a.ranking.local - b.ranking.local
          || a.student.name.last.localeCompare(b.student.name.last)
          || a.student.name.first.localeCompare(b.student.name.first)
    },

    getComputedChanges() {
      const values = []
      this.table.initialItems.forEach(item => {
        const changes = this.save.changes.filter(change => change.id === item.id)
        if(changes.length) {
          const input = {
            id: item.id,
            selection: { id: item?.selection?.id, state: null, ensemble: null, part: null, accepted: null, invoice: item?.selection?.invoice },
            type: null,
            changes: [],
            saving: false,
            success: null,
            error: null,
            icon: 'circle',
            variant: 'primary'
          }

          const stateChange = this.save.changes.find(change => change.id === item.id && change.key === 'state')
          if(stateChange) {
            input.selection.state = stateChange.value
            input.changes.push({ type: 'state rank', from: item.selection.state, to: stateChange.value})
          }
          else { input.selection.state = item.selection.state }

          const ensembleChange = this.save.changes.find(change => change.id === item.id && change.key === 'ensemble')
          if(ensembleChange) {
            input.selection.ensemble = ensembleChange.value
            input.selection.accepted = null
            input.changes.push({ type: 'ensemble', from: this.getEnsembleName(item.selection.ensemble), to: this.getEnsembleName(ensembleChange.value)})
            input.changes.push({ type: 'accepted', from: item.selection.accepted, to: null})
          }
          else {
            input.selection.ensemble = item.selection.ensemble
            input.selection.accepted = item.selection.accepted
          }

          const partChange = this.save.changes.find(change => change.id === item.id && change.key === 'part')
          if(partChange) {
            input.selection.part = partChange.value
            input.changes.push({ type: 'part', from: item.selection.part, to: partChange.value})
          }
          else { input.selection.part = item.selection.part }

          /** Determine Type **/
          if(!input.selection?.id) { input.type = 'create' }
          else if(input?.selection?.id && input.selection.state === null && input.selection.ensemble === null && input.selection.part === null) { input.type = 'delete' }
          else { input.type = 'update' }

          values.push(input)
        }
      })
      return values
    },
    async saveChanges() {
      this.table.busy = true
      this.save.complete = false

      //Refresh the settings to make sure that selections are still enabled.
      await this.getSetting()

      if(this.isEnabled) {
        this.save.processing = true
        this.save.status = 'Computing Changes'
        this.save.computedChanges = this.getComputedChanges()

        this.save.status = 'Saving Changes'
        await this.save.computedChanges.reduce(async (referencePoint, change, index) => {
          try {
            change.saving = true
            await referencePoint;
            this.save.progress = index + 1
            if(change.type === 'create') {
              const id = uuid.v4()
              const selectionInput = {
                id: id,
                selectionApplicationId: change.id,
                selectionEnsembleId: change.selection.ensemble,
                part: change.selection.part,
                ranking: { state: change.selection.state },
              }
              const applicationInput = { id: change.id, applicationSelectionId: id }

              await API.graphql(graphqlOperation(createSelection, { input: selectionInput }));
              await API.graphql(graphqlOperation(updateApplication, { input: applicationInput } ));
              change.selection.id = id
            }
            else if(change.type === 'update') {
              const input = {
                id: change.selection.id,
                part: change.selection.part,
                accepted: change.selection.accepted,
                selectionEnsembleId: change.selection.ensemble,
                ranking: {
                  state: change.selection.state
                }
              }
              await API.graphql(graphqlOperation(updateSelection, { input: input } ));
            }
            else if(change.type === 'delete') {
              const applicationInput = { id: change.id, applicationSelectionId: '' }
              const selectionInput = { id: change.selection.id }

              await API.graphql(graphqlOperation(updateApplication, { input: applicationInput } )); //detach selection from application
              await API.graphql(graphqlOperation(deleteSelection, { input: selectionInput })); //delete the selection
              change.selection.id = null
            }

            change.success = true
            change.variant = 'success'
            change.icon = 'check-circle-fill'
            change.updatedAt = new Date().toISOString()
          }
          catch (e121) {
            console.error(e121)
            change.success = false
            change.variant = 'danger'
            change.icon = 'x-circle-fill'
            change.error = e121
            this.save.errors.push({ application: change.id, error: e121})
          }
          finally {
            change.saving = false
          }
        }, Promise.resolve());


        // Update Initial Items, as well as revert back bad values in the table to their initial value
        this.save.status = 'Updating Initial Items'
        this.save.computedChanges.forEach(change => {
          const initialItem = this.table.initialItems.find(item => item.id === change.id)
          if(initialItem) {
            initialItem.selection = change.selection
          }

          const tableItem = this.table.items.find(item => item.id === change.id)
          if(tableItem) {
            tableItem.selection = JSON.parse(JSON.stringify(change.selection))
            tableItem.updatedAt = change?.updatedAt ? change?.updatedAt : tableItem.updatedAt
          }
        })

        this.save.processing = false
        this.save.changes = []
        this.save.complete = true

        this.table.busy = false
      }
      else {
        this.ui.error.show = true
        this.ui.error.message = 'Selections have been disabled, all changes made were not be saved.'
      }
    },

    /** Input **/
    onChange(key, value, rules, application) {
      if(value === '') {
        // eslint-disable-next-line no-param-reassign
        value = null
        application.selection[key] = null
      }

      validate(value, rules).then(result => {
        if (result.valid) {
          const initialItem = this.table.initialItems.find(item => item.id === application.id)
          if(initialItem?.selection[key] === value) {
            this.save.changes = this.save.changes.filter(change => !(change.id === application.id && change.key === key))
          }
          else {
            const changeValue = { id: application.id, key: key, value: value, date: new Date().toISOString() }
            const index = this.save.changes.findIndex(change => change.id === application.id && change.key === key)
            if(index >= 0) {
              this.save.changes.splice(index, 1, changeValue)
            }
            else {
              this.save.changes.push(changeValue)
            }
          }
        }
        else {
          this.save.changes = this.save.changes.filter(change => !(change.id === application.id && change.key === key))
        }
      })
    },
    onBlur(key, item) {
      const initialItem = this.table.initialItems.find(ii => ii.id === item.id)
      if(initialItem && initialItem?.selection[key] === item?.selection[key]) {
        this.$refs[`${item.id}-${key}-provider`].reset()
      }
    },
    undoToInitialItem(key, item) {
      const initialItem = this.table.initialItems.find(initial => initial.id === item.id)
      if(initialItem) {
        item.selection[key] = initialItem.selection[key]
        this.save.changes = this.save.changes.filter(change => !(change.id === item.id && change.key === key))
        this.$refs[`${item.id}-${key}-provider`].reset()
      }
    },
    getInputGroupClass(validationContext) {
      const state = this.getValidationState(validationContext)
      if(state === null) return ''
      return state ? 'is-valid' : 'is-invalid'
    },
    fromToText(from, to) {
      return `from ${from || 'null'} to ${to || 'null'}`
    },

    /** Util **/
    getGroup(ranking) {
      if(ranking.score === 100 && ranking.local === 1) return 1
      if((ranking.score === 100 && ranking.local > 1) || (ranking.score === 99 && ranking.local === 1)) return 2
      if((ranking.score === 99 && ranking.local > 1) || (ranking.score < 99 && ranking.local > 0)) return 3
      return 0
    },
    getGroupClass(item, type) {
      if(item && !this.table.filter) {
        if(item.group === 1) return 'page-break'
        if(item.group === 2) return 'bg-light page-break'
        if(item.group === 3) return 'tr-group-3 page-break'
        return ''
      }
      return null
    },
    getEnsembleCode(id) {
      return this.ensembles.items.find(item => item.id === id)?.code || ''
    },
    getEnsembleName(id) {
      return this.ensembles.items.find(item => item.id === id)?.name || null
    },
    getZoneName(festival) {
      if(festival === null || festival.zone === null) return null
      const prefix = 'Zone ';
      if (festival.zone.name.startsWith(prefix)) {
        return festival.zone.name.substring(prefix.length);
      }
      return festival.zone.name
    },



    onStateRankingInput(selection, value) {
      if(value === '') {
        selection.state = null
      }
    },
    getParts(ensembleId) {
      const parts = []
      const ensemble = this.ensembles.items.find(item => item.id === ensembleId)
      if(ensemble) {
        for (let i = 1; i <= ensemble.parts; i += 1) {
          parts.push(i.toString())
        }
      }
      return parts
    },
    filterEnsembles(options, search) {
      const fuse = new Fuse(options, {
        keys: [ { name: 'code', weight: 1 }, { name: 'name', weight: 0.75} ],
        threshold: 0.2,
        shouldSort: true,
      })
      return search.length ? fuse.search(`${search}`).map(({ item }) => item) : fuse.list
    },
    getSaveIcon(save) {
      if(save.complete) {
        if(save.computedChanges.every(item => item.success)) return 'check-circle-fill'
        if(save.computedChanges.every(item => item.error)) return 'x-circle-fill'
        return 'slash-circle-fill'
      }
      return 'circle'
    },
    getSaveVariant(save) {
      if(save.complete) {
        if(save.computedChanges.every(item => item.success)) return 'success'
        if(save.computedChanges.every(item => item.error)) return 'danger'
        return 'warning'
      }
      return 'primary'
    },
    getSaveClass() {
      if(this.save.processing || this.save.complete) {
        if(this.$refs['processed-scrollbar']?.ps?.scrollbarYActive === true) {
          return 'mr-2'
        }
      }
      return ''
    },
    searchAndExpandItem(id) {
      this.table.search = id
      const row = this.table.items.find(item => item.id === id)
      if(row) {
        // eslint-disable-next-line no-underscore-dangle
        row._showDetails = true
      }
    },
    searchAndExpandItems(id) {
      this.table.search = id
      this.table.items.filter(item => item.student.id === id).forEach(item => {
        // eslint-disable-next-line no-underscore-dangle
        item._showDetails = true
      })
    },

    /** Validation **/
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    getValidationClass(validationContext) {
      const state = this.getValidationState(validationContext)
      if(state === null) return null
      return state ? 'is-valid' : 'is-invalid'
    },
    canUpdate(ref) {
      return new Promise((resolve, reject) => {
        this.$refs[ref].validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  }
}
</script>

<style>
  .td-details-row {
    border-top: 0!important;
    padding: 0!important;
  }

  .table.b-table > tbody > tr.b-table-details > td {
    border-top: none !important;
  }

  .b-table-details > td > table > tbody > tr:first-child > td {
    border-top: 0!important;
  }

  .tr-group-2 {
    background-color: #f5f5f5!important;
  }
  .rounded-bottom-only {
    border-radius: 0 0 0.357rem 0.357rem;
  }

  .alert .list-group .list-group-item:hover {
    background-color: #fff;
  }
  .ps-changes {
    max-height: 300px;
  }
  .ps--active-y.ps-changes {
    padding-right: 1.5rem
  }
  .ps-processed {
    max-height: 50vh;
  }
  .ps--active-y.ps-processed {
    padding-right: 1.5rem
  }
  .alert .list-group .list-group-item:hover {
    background-color: #fff;
  }


  @media (max-width: 1400px) {
    .table th.col-local > div {
      display: none;
    }

    .table th.col-local::after {
      content: "Local";
      display: block;
    }
    .table th.col-state > div {
      display: none;
    }

    .table th.col-state::after {
      content: "State";
      display: block;
    }
  }


	@media print {
		.table th.col-local > div {
			display: none;
		}
		.table th.col-local::after {
			content: "LR";
			display: block;
		}
		.table th.col-state > div {
			display: none;
		}
		.table th.col-state::after {
			content: "SR";
			display: block;
		}

	}

</style>

<style lang="scss" scoped>
    ::v-deep {
      .col-student {
        //width: 30%;
        vertical-align: middle !important;
      }
      .col-festival {
        //width: 10%;
        vertical-align: middle !important;
      }
      .col-score {
        //width: 10%;
        vertical-align: middle !important;
      }
      .col-local {
        //width: 10%;
        vertical-align: middle !important;
      }
      .col-state {
        width: 10%;
        vertical-align: middle !important;
      }
      .col-ensemble {
        width: 20%;
        padding-left: 0!important;
        padding-right: 0!important;
        vertical-align: middle !important;
      }
      .col-part {
        width: 10%;
        vertical-align: middle !important;
      }

      @media (max-width: 1530px) {
        /*.media-aside {
          display: none!important;
          margin-right:0!important;
        }
        .b-avatar {
          display: none!important;
        }*/
        .col-state {
          width: 12%!important;
        }
        .col-ensemble {
          width: 22%!important;
        }
        .col-part {
          width: 12%!important;
        }
        .student-school {
          display: none!important;
        }
        .festival-prefix {
          display: none!important;
        }
      }


      @media print {
        table * {
					font-size: 12px!important;
        }

				.td-details-row {
					display: none!important;
				}

        thead {
          page-break-before:unset!important;
          page-break-inside: unset!important;
          page-break-after:unset!important;
        }

        .card .card-header {
          padding-top: 0!important;
        }
        .form-control {
          border: 0!important;
        }
        .vs__dropdown-toggle {
          border: 0!important;
        }
        .vs__actions {
          display: none!important;
        }
        .media-aside {
          margin-right: 0;
        }
        .b-avatar {
          display: none;
        }
        .col-student {
          width: auto!important;
        }
        .col-festival {
          width: auto!important;
        }
        .col-score {
          width: auto!important;
        }
        .col-local {
          width: auto!important;
        }
        .col-state {
          width: auto!important;
        }
        .col-ensemble {
          width: auto!important;
        }
        .col-part {
          width: auto!important;
        }
      }
    }

    /*.v-select::v-deep {
      .vs__selected-options {
        flex-wrap: nowrap;
        max-width: calc(100% - 40px); !* change this to `- 40px` if you're supporting a `clearable` field; I was not *!
      }
      .vs__selected {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
        overflow: hidden;
      }
    }*/
</style>
