// eslint-disable-next-line import/prefer-default-export

export const getSetting = /* GraphQL */ `
    query GetSetting($key: String!) {
        getSetting(key: $key) {
            key
            value
        }
    }
`;

export const getUser = /* GraphQL */ `
    query GetUser($id: ID!) {
        getUser(id: $id) {
            id
            username
            name {
                first
                last
            }
            ensembleIds
            instrumentIds
        }
    }
`;

export const listInstruments = /* GraphQL */ `
    query ListInstruments(
        $id: ID
        $filter: ModelInstrumentFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listInstruments(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
                order
            }
            nextToken
        }
    }
`;

export const listEnsembles = /* GraphQL */ `
    query ListEnsembles(
        $id: ID
        $filter: ModelEnsembleFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listEnsembles(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
                code
                state {
                    enabled 
                }
            }
            nextToken
        }
    }
`;

export const listApplications = /* GraphQL */ `
    query ListApplications(
        $instrumentId: ID
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listApplications(
            filter: { applicationInstrumentId: { eq: $instrumentId }}
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                festival {
                    name
                    zone {
                        name
                    }
                }
                instrument {
                    id
                    name
                }
                student {
                    id
                    name {
                        first
                        last
                    }
                    school {
                        id
                        name {
                            legal
                        }
                    }
                    applications(filter: { applicationInstrumentId: { ne: $instrumentId } }) {
                        items {
                            id
                            instrument {
                                name
                            }
                            festival {
                                name
                            }
                            ranking {
                                local
                                score
                            }
                            selection {
                                id
                                part
                                ranking {
                                    state
                                }
                                ensemble {
                                    id
                                    name
                                    code
                                    hasParts
                                }
                                accepted
                                selectionEnsembleId
                            }
                        }
                    }
                }
                ranking {
                    score
                    local
                }
                selection {
                    id
                    part
                    ranking {
                        state
                    }
                    ensemble {
                        id
                    }
                    accepted
                    selectionEnsembleId
                }
            }
            nextToken
        }
    }
`;

export const listApplicationsWithNullInstrument = /* GraphQL */ `
    query ListApplications(
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listApplications(
            filter: { applicationInstrumentId: { attributeExists: false }}
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                festival {
                    name
                    zone {
                        name
                    }
                }
                instrument {
                    id
                    name
                }
                student {
                    id
                    name {
                        first
                        last
                    }
                    school {
                        id
                        name {
                            legal
                        }
                    }
                    applications(filter: { applicationInstrumentId: { attributeExists: true } }) {
                        items {
                            id
                            instrument {
                                name
                            }
                            festival {
                                name
                            }
                            ranking {
                                local
                                score
                            }
                            selection {
                                id
                                part
                                ranking {
                                    state
                                }
                                ensemble {
                                    id
                                    name
                                    code
                                    hasParts
                                }
                                accepted
                                selectionEnsembleId
                            }
                        }
                    }
                }
                ranking {
                    score
                    local
                }
                selection {
                    id
                    part
                    ranking {
                        state
                    }
                    ensemble {
                        id
                    }
                    accepted
                    selectionEnsembleId
                }
            }
            nextToken
        }
    }
`;

export const getApplicationDetails = /* GraphQL */ `
    query GetApplication(
        $id: ID!
    ) {
        getApplication(id: $id) {
            id
            form {
                name
                type
            }
            student {
                id
                grade
                dob
                address {
                    line1
                    line2
                    city
                    state
                    zip
                }
                phone {
                    type
                    number
                    ext
                }
                email {
                    type
                    address
                }
            }
            questions {
                lastYear {
                    attended
                    group
                    part
                    chair
                }
                moreThanOne {
                    response
                    instruments
                }
                organizations {
                    school
                    other
                }
                experience {
                    instrument {
                        years
                        doubles
                    }
                    vocalJazzAccompaniment
                    jazzEnsemble
                    orchestral
                }
                previousSoloRating {
                    ninth {
                        grade
                        level
                    }
                    tenth {
                        grade
                        level
                    }
                }
            }
            grading {
                tone
                rhythmicSense
                leadership
                maturity
                articulation
                intonation
                citizenship
                diction
                flexibility
                generalMusicianship
                attitude
                technique
                sightReading
                responsibility
            }
            recommendation
            comments
        }
    }
`;

export const getApplication = /* GraphQL */ `
    query GetApplication(
        $id: ID!
    ) {
        getApplication(id: $id) {
            id
            festival {
                name
            }
            instrument {
                name
            }
            student {
                id
                name {
                    first
                    last
                }
                school {
                    id
                    name {
                        legal
                    }
                }
                applications {
                    items {
                        id
                        instrument {
                            name
                        }
                        festival {
                            name
                        }
                        ranking {
                            local
                            score
                        }
                        selection {
                            id
                            part
                            ranking {
                                state
                            }
                            ensemble {
                                id
                                name
                                code
                                hasParts
                            }
                            accepted
                        }
                    }
                }
            }
            ranking {
                score
                local
            }
            selection {
                id
                part
                ranking {
                    state
                }
                ensemble {
                    id
                }
                accepted
            }
        }
    }
`;
export const updateApplication = /* GraphQL */ `
    mutation UpdateApplication(
        $input: UpdateApplicationInput!
        $condition: ModelApplicationConditionInput
    ) {
        updateApplication(input: $input, condition: $condition) {
            id
        }
    }
`;

export const createSelection = /* GraphQL */ `
    mutation CreateSelection(
        $input: CreateSelectionInput!
        $condition: ModelSelectionConditionInput
    ) {
        createSelection(input: $input, condition: $condition) {
            id
            part
            ranking {
                state
            }
            accepted
            application {
                id
                student {
                    id
                    name {
                        first
                        last
                    }
                    grade
                    school {
                        name {
                            legal
                        }
                    }
                    applications {
                        items {
                            id
                            applicationInstrumentId
                            selection {
                                id
                                part
                                ranking {
                                    state
                                }
                                accepted
                                selectionEnsembleId
                            }
                        }
                    }
                }
                instrument {
                    id
                    name
                    order
                }
                ranking {
                    local
                    score
                }
                festival {
                    name
                }
                applicationInstrumentId
                applicationSelectionId
            }
            state {
                order
            }
            createdAt
            updatedAt
            selectionEnsembleId
        }
    }
`;
export const updateSelection = /* GraphQL */ `
    mutation UpdateSelection(
        $input: UpdateSelectionInput!
        $condition: ModelSelectionConditionInput
    ) {
        updateSelection(input: $input, condition: $condition) {
            id
            part
            ranking {
                state
            }
            accepted
            application {
                id
                student {
                    id
                    name {
                        first
                        last
                    }
                    grade
                    school {
                        name {
                            legal
                        }
                    }
                    applications {
                        items {
                            id
                            applicationInstrumentId
                            selection {
                                id
                                part
                                ranking {
                                    state
                                }
                                accepted
                                selectionEnsembleId
                            }
                        }
                    }
                }
                instrument {
                    id
                    name
                    order
                }
                ranking {
                    local
                    score
                }
                festival {
                    name
                }
                applicationInstrumentId
                applicationSelectionId
            }
            state {
                order
            }
            createdAt
            updatedAt
            selectionEnsembleId
        }
    }
`;
export const deleteSelection = /* GraphQL */ `
    mutation DeleteSelection(
        $input: DeleteSelectionInput!
        $condition: ModelSelectionConditionInput
    ) {
        deleteSelection(input: $input, condition: $condition) {
            id
        }
    }
`;

export const deleteInvoice = /* GraphQL */ `
    mutation DeleteInvoice(
        $input: DeleteInvoiceInput!
        $condition: ModelInvoiceConditionInput
    ) {
        deleteInvoice(input: $input, condition: $condition) {
            id
        }
    }
`;

export const onUpdateApplication = /* GraphQL */ `
    subscription OnUpdateApplication {
        onUpdateApplication {
            id
            student {
                id
            }
        }
    }
`;

